
.heading {
  font-size: 30px;
  color: #505050;
}

.timeleft {
  font-size: 30px;
  color: #fff;
  font-weight: bold;
  animation-name: timeleft-text;
  animation-duration: 4s;
}
@keyframes timeleft-text {
  0%   {color: red;}
  25%  {color:blue;}
  50%  {color:green;}
  100% {color: yellow;}
}

h3 {
  margin: 20px 0;
}

.time-box-wrapper {
  height: 8vh;
  margin-top: 70px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.single-box {
  text-align: center;
  border-right: 1px solid #333333;
  padding: 0 34px;
  
  &:last-child {
    border: none;
  }
  
.heading {
    margin: 0px;
    font-size: 56px;
    font-weight: 500;
    color: white;
}
p {
    margin: 0px;
    color: #5b5757;
    white-space: nowrap;
    font-weight: bold;
  }
}

.carousel-caption {
  position: absolute;
  right: 15%;
  /* bottom: 1.25rem; */
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
  top: 30% !important;
}


/**************
css for marquee
***************/
.marquee {
  white-space: nowrap;
  overflow: hidden;
  margin: 0 auto;
  padding: 3rem 0;
  background-color: #e96c42;
}

.marquee .marquee_text {
  display: inline-block;
  padding-left: 100%;
  font-size: 2rem;
  text-transform: uppercase;
  /* color: white; */
  animation: marquee-right-left 16s linear infinite;
}

@keyframes marquee-right-left {
    0%   { transform: translateX(0); }
    100% { transform: translateX(-100%); }
}


.Carousel.Item img {
  height: 100vh;
  max-height: 500px;
}


@media all and (max-width: 793px) {
  .single-box {
    text-align: center;
    border-right: 1px solid #333333;
    padding: 0 3px;
  }
  .timeleft {
    font-size: 19px;
}
.heading {
  font-size: 20px;
}
.single-box p{
  font-size: 13px;
}
.single-box .heading {
  font-size: 28px;
}
}



/* CSS For the New count down */

body {
  margin: 0;
  /* display: flex; */
  justify-content: center;
  height: 100vh;
  text-align: center;
  color: #333;
  /* background: radial-gradient(circle, rgb(63 177 251) 0%, rgba(70,227,252,1) 99%); */
  background: #19224d !important;
}

.timer-header {
  color: white;
  width: 100vw;
  padding: 18px 0;
}

.title {
  margin: 0;
}

.subtitle {
  max-width: 576px;
  margin: 36px auto;
  letter-spacing: .1em;
  line-height: 1.3em;
}

.countdown-wrapper {
    width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 -8px 0 -8px;
  padding-bottom: 20px;
}
.time-section {
  padding: 0px 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: #254864 !important;
}
.time {
    margin: 0;
  font-size: 60px !important;
  font-weight: 800 !important;
}
.small {
    font-size: 18px !important;
}
.time-text{
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}

@media all and (max-width: 466px) {
.time {
  font-size: 22px !important;
}
.time-text {  
  font-size: 13px;
}
}